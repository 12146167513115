import React, { useEffect, useState } from "react";
import {
  aboutus1,
  aboutus2,
  aboutus3,
  aboutus4,
  aboutus5,
  aboutus6,
  aboutus7,
  aboutus8,
  aboutus9,
  cor1,
  cor2,
  cor3,
  cor4,
  cor5,
  footer,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  gallery09,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
  gallery15,
  homepageCorousal1,
  homepageCorousal2,
  homepageCorousal3,
  homepageCorousal4,
} from "../assets/exports";
import Carousel from "react-bootstrap/Carousel";
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Carousel as MyCustomCarousel } from "primereact/carousel";
import { TabView, TabPanel } from "primereact/tabview";
import AOS from "aos";
import "aos/dist/aos.css";
import "./css/about.css"
import NavDropdown from "react-bootstrap/NavDropdown";
import "./css/header.css";
// import { NavLink } from "react-router-dom";
import { Accordion, NavLink } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { Badge } from "primereact/badge";
import { Image } from "primereact/image";
import { Footer } from "./Footer";
import { HeaderBase } from "./HeaderBase";
export const Services = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
       
    window.scrollTo(0, 0);
  }, []);
  const [clientNum, setClientNum] = useState({
    clients: 45,
    projects: 115,
    squareFeet: 230,
  });
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 500,
      easing: "ease-out-cubic",
    });
  }, []);
  const [timerId, setTimerId] = useState(0);
  const images = [
    { source: gallery01, alt: "Image 1" },
    { source: gallery02, alt: "Image 2" },
    { source: gallery03, alt: "Image 3" },
    { source: gallery04, alt: "Image 4" },
    { source: gallery05, alt: "Image 5" },
    { source: gallery06, alt: "Image 6" },
    { source: gallery07, alt: "Image 7" },
    { source: gallery08, alt: "Image 8" },
    { source: gallery09, alt: "Image 9" },
    { source: gallery10, alt: "Image 10" },
    { source: gallery11, alt: "Image 11" },
    { source: gallery12, alt: "Image 12" },
    { source: gallery13, alt: "Image 13" },
    { source: gallery14, alt: "Image 14" },
    { source: gallery15, alt: "Image 15" },
    // Add more images here
  ];

  const itemTemplate = (image) => {
    return (
      <div
        className="border-1 surface-border border-round m-2 text-center overflow-hidden"
        style={{ height: "300px", width: "330px" }}
      >
        <div className="">
          <Image  loading="lazy" 
            src={image.source}
            alt={image.alt}
            height="300px"
            width="330px"
            style={{ height: "300px", width: "330px" }}
            preview
          />
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (timerId == 0) {
      let timer = setInterval(() => {
        setClientNum((clientNum) => {
          setTimerId(1);
          if (clientNum.clients > 63) {
            clearInterval(timerId);
            return clientNum;
          } else {
            return {
              clients: clientNum?.clients + 1,
              projects: clientNum?.projects + 1,
              squareFeet: clientNum?.squareFeet + 1,
            };
          }
        });
        setTimerId(timer);
      }, 200);
      setTimerId(timer);
    }
  }, []);
  return (
    <>
      <div className="homepage-container about-container">
        <div className="w-100 flex-wrap justify-content-center homepage-nav ">
        <HeaderBase navigate={navigate}/>
        </div>
        <div className="" style={{ height: "100vh" }}>
        <div className="corusel" style={{ height: "100vh" }}>
          <Carousel>
            <Carousel.Item interval={100000} className="">
              <img
                src={aboutus9}
                style={{ height: "101vh", width: "100%" }}
                className="zoom-image"
                text="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={4000} className="">
              <img
                src={gallery03}
                style={{ height: "101vh", width: "100%" }}
                className="zoom-image"
                text="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={4000} className="">
              <img
                src={gallery06}
                style={{ height: "101vh", width: "100%" }}
                className="zoom-image"
                text="First slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={4000} className="">
              <img
                src={aboutus2}
                style={{ height: "101vh", width: "100%" }}
                className="zoom-image"
                text="Third slide"
              />
            </Carousel.Item>
          </Carousel>
        </div>
        </div>
        <div className="mt-5 w-100 pt-2 d-flex flex-wrap align-items-center justify-content-center mb-5 w-100">
          <div className="d-flex flex-wrap justify-content-center align-items-center  w-100">
            <div
              className="text-left align-self-center justify-self-center fs-md-5 w-50"
              style={{
                // maxWidth: "1200px",
                fontFamily: "Popins",
                fontWeight: "700",
                lineHeight: "1em",
                letterSpacing: "-0.04em",
              }}
              data-aos="flip-up"
            >
            Our <br/> Services
              <hr className="mt-0 w-75" />
              <p
                style={{
                  letterSpacing: "0.1px",
                  fontWeight: "700",
                  lineHeight: "1em",
                  fontSize: "2rem",
                }}
              >
               Construction Services that Go Beyond Expectations
              </p>
            </div>
            <div
              className="d-flex flex-wrap justify-content-between align-items-center "
              data-aos="slide-down"
            >
            <div className="px-3">

              <div className="row mt-5 fw-bolder " data-aos="slide-up">
                <ul class=" col mt-2 " style={{ listStyle: "none" }}>
                  <li className="list-hmpg mt-2 " style={{ fontSize: "18px" }}>
                    <span classname="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                    </span>{" "}
                    Consultation
                  </li>
                  <li className="list-hmpg mt-2 " style={{ fontSize: "18px" }}>
                    <span classname="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                    </span>{" "}
                    Space Planning
                  </li>
                  <li className="list-hmpg mt-2 " style={{ fontSize: "18px" }}>
                    <span classname="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                    </span>{" "}
                    Design Development
                  </li>
                </ul>
                <ul
                  class="services-list col mt-2 "
                  style={{ listStyle: "none" }}
                >
                  <li className="list-hmpg mt-2 " style={{ fontSize: "18px" }}>
                    <span classname="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                    </span>{" "}
                    Design Development
                  </li>
                  <li className="list-hmpg mt-2 " style={{ fontSize: "18px" }}>
                    <span classname="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                    </span>{" "}
                    3D Visualization
                  </li>
                  <li className="list-hmpg mt-2 " style={{ fontSize: "18px" }}>
                    <span classname="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                    </span>{" "}
                    Lighting Design
                  </li>
                </ul>
              </div>
            </div>
            </div>
          </div>
        </div>

        <div className="mt-5 w-100 pt-2 d-flex flex-wrap align-items-center justify-content-center mb-5 ">
          <div
            className="d-flex flex-wrap justify-content-center align-items-center  w-100 px-5 mt-5"
            data-aos=""
          >
            <div
              className="text-left align-self-center justify-self-center  w-50 "
              data-aos="flip-up"
            >
              <p
                style={{
                  fontWeight: "400",
                  lineHeight: "1.5rem",
                  fontSize: "1rem",
                }}
              >
 <Accordion defaultActiveKey="0" flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header classname="fw-bolder" data-aos="slide-in" >Interior Designing</Accordion.Header>
        <Accordion.Body>
                  <li>Budget Planning</li>
                  <li>Space Planning</li>
                  <li>Concept Development</li>
                  <li>Color Consultation</li>
                  <li>Material Selection</li>
                  <li>Window Treatments</li>


        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header classname="fw-bolder" data-aos="slide-in">Raw Material Supplier</Accordion.Header>
        <Accordion.Body>
                  <li>Plywood</li>
                  <li>Laminates</li>
                  <li>Veeners</li>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header classname="fw-bolder" data-aos="slide-in">Furniture and Sofa fitting Suppliers</Accordion.Header>
        <Accordion.Body>
                  <li>All Hardwares</li>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header classname="fw-bolder" data-aos="slide-in">Sofa Wholesaler and Retailers</Accordion.Header>
        <Accordion.Body>
                  <li>Sofa Manufacturing and customization according to your needs.</li>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
              </p>
            </div>
            <div
              className="d-flex flex-wrap justify-content-between align-items-center ml-5 pl-3 simp-img"

              data-aos="slide-down"
            >
              <img lazy="true"  src={gallery13} className="w-100 h-100" />
            </div>
          </div>
          <div
            className="d-flex flex-wrap justify-content-center align-items-center  w-100 px-5 mt-5"
            data-aos=""
          >
           
          </div>
 
        </div>

        <Footer />
      </div>
    </>
  );
};
