import React, { useEffect, useState } from "react";
import {
  aboutus1,
  aboutus2,
  aboutus3,
  aboutus4,
  aboutus5,
  aboutus6,
  aboutus7,
  aboutus8,
  aboutus9,
  cor1,
  cor2,
  cor3,
  cor4,
  cor5,
  footer,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  gallery09,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
  gallery15,
  homepageCorousal1,
  homepageCorousal2,
  homepageCorousal3,
  homepageCorousal4,
  warehouse1,
  warehouse2,
  warehouse3,
  warehouse4,
  warehouse5,
  warehouse6,
} from "../assets/exports";
import Carousel from "react-bootstrap/Carousel";
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import { Carousel as MyCustomCarousel } from "primereact/carousel";
import { TabView, TabPanel } from "primereact/tabview";
import AOS from "aos";
import "aos/dist/aos.css";

import NavDropdown from "react-bootstrap/NavDropdown";
import "./css/header.css";
// import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { Badge } from "primereact/badge";
import { Image } from "primereact/image";
import { Footer } from "./Footer";
import { HeaderBase } from "./HeaderBase";
export const Warehouse = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [clientNum, setClientNum] = useState({
    clients: 45,
    projects: 115,
    squareFeet: 230,
  });
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 500,
      easing: "ease-out-cubic",
    });
  }, []);
  const [timerId, setTimerId] = useState(0);
  const images = [
    { source: gallery01, alt: "Image 1" },
    { source: gallery02, alt: "Image 2" },
    { source: gallery03, alt: "Image 3" },
    { source: gallery04, alt: "Image 4" },
    { source: gallery05, alt: "Image 5" },
    { source: gallery06, alt: "Image 6" },
    { source: gallery07, alt: "Image 7" },
    { source: gallery08, alt: "Image 8" },
    { source: gallery09, alt: "Image 9" },
    { source: gallery10, alt: "Image 10" },
    { source: gallery11, alt: "Image 11" },
    { source: gallery12, alt: "Image 12" },
    { source: gallery13, alt: "Image 13" },
    { source: gallery14, alt: "Image 14" },
    { source: gallery15, alt: "Image 15" },
    // Add more images here
  ];

  const itemTemplate = (image) => {
    return (
      <div
        className="border-1 surface-border border-round m-2 text-center overflow-hidden"
        style={{ height: "300px", width: "330px" }}
      >
        <div className="">
          <Image  loading="lazy" 
            src={image.source}
            alt={image.alt}
            height="300px"
            width="330px"
            style={{ height: "300px", width: "330px" }}
            preview
          />
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (timerId == 0) {
      let timer = setInterval(() => {
        setClientNum((clientNum) => {
          setTimerId(1);
          if (clientNum.clients > 63) {
            clearInterval(timerId);
            return clientNum;
          } else {
            return {
              clients: clientNum?.clients + 1,
              projects: clientNum?.projects + 1,
              squareFeet: clientNum?.squareFeet + 1,
            };
          }
        });
        setTimerId(timer);
      }, 200);
      setTimerId(timer);
    }
  }, []);
  return (
    <>
      <div className="homepage-container about-container">
        <div className="w-100 flex-wrap justify-content-center homepage-nav ">
          <HeaderBase navigate={navigate} />
        </div>
        <div className="" style={{ height: "80vh" }}>
          <img
            src={homepageCorousal1}
            style={{ height: "80vh", width: "100%" }}
            className=""
            text="Second slide"
            // data-aos="zoom-in"
          />
        </div>
        <div className="mt-0 w-100 pt-2 d-flex flex-wrap align-items-center justify-content-center mb-5 w-100">
          <div className="d-flex flex-wrap justify-content-center align-items-center  w-100">
            <div
              className="text-left align-self-center justify-self-center fs-md-5 w-50 "
              style={{
                // maxWidth: "1200px",
                fontFamily: "Popins",
                fontWeight: "700",
                lineHeight: "1em",
                letterSpacing: "-0.04em",
              }}
              data-aos="flip-up"
            >
              Our Warehouse
              <hr className="mt-0 w-75" />
              <p
                style={{
                  letterSpacing: "0.1px",
                  fontWeight: "700",
                  lineHeight: "1em",
                  fontSize: "2rem",
                }}
              >
                Where goods await their call to action.
              </p>
            </div>
            <div
              className="d-flex flex-wrap justify-content-between align-items-center  warehouse"
            
              data-aos="slide-down"
            >
              <img lazy="true"  src={warehouse6} style={{ height: "15rem" }} />
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-wrap align-items-center justify-content-center"
          style={{ fontFamily: "Jost", fontSize: "1rem", lineHeight: "2rem" }}
        >
          <div
            className=" w-100 px-8"
            style={{ fontFamily: "Jost", fontSize: "1rem", lineHeight: "2rem" }}
            data-aos="slide-down"
          >
            The role of a warehouse transcends mere storage; it symbolizes the
            intricate choreography of the modern marketplace. It's a sanctuary
            where products rest, awaiting their call to action, while serving as
            the fulcrum for economies, logistics, and the dynamic rhythm of
            global trade. At Dream Home Interiors, our journey began amid the
            whispers of creativity and the steady rhythm of challenges. For over
            15 years, we've navigated the intricate world of interior design,
            sculpting spaces that echo stories and embody dreams.
          </div>
        </div>
        <div className="mt-5 w-100 pt-2 d-flex flex-wrap align-items-center justify-content-center mb-5 ">
          <div
            className="d-flex flex-wrap justify-content-center align-items-center  w-100 px-5 mt-5"
            data-aos=""
          >
            <div
              className="text-left align-self-center justify-self-center  w-50 "
              data-aos="flip-up"
            >
              <p
                style={{
                  fontWeight: "400",
                  lineHeight: "1.5rem",
                  fontSize: "1rem",
                }}
              >
                Behind the grand doors of a warehouse lies the silent artistry
                of logistics—a world where inventory management transforms into
                a ballet of efficiency. Every pallet stacked is a testament to
                the meticulous orchestration of resources, paving the way for
                seamless distribution and customer satisfaction.
              </p>
            </div>
            <div
              className="d-flex flex-wrap justify-content-between align-items-center ml-5 pl-3 simp-img"

              data-aos="slide-down"
            >
              <img lazy="true"  src={warehouse1} className="w-100 h-100" />
            </div>
          </div>
          <div
            className="d-flex flex-wrap justify-content-center align-items-center  w-100 px-5 mt-5"
            data-aos=""
          >
            <div
              className="d-flex flex-wrap justify-content-between align-items-center   mr-5 pr-3 simp-img"
       
              data-aos="slide-down"
            >
              <img lazy="true"  src={warehouse2} className="w-100 h-100" />
            </div>
            <div
              className="text-left align-self-center justify-self-center  w-50 "
              data-aos="flip-up"
            >
              <p
                style={{
                  fontWeight: "400",
                  lineHeight: "1.5rem",
                  fontSize: "1rem",
                }}
              >
                In the vast expanse of a warehouse, we find the silent pulse of
                commerce, resonating with the heartbeat of industry. It's not
                just a repository for goods; it's the nexus where efficiency,
                technology, and strategy converge, empowering businesses to
                bridge the gap between supply and demand.
              </p>
            </div>
          </div>
          <div
            className="d-flex flex-wrap justify-content-center align-items-center  w-100 pl-5 mt-5"
            data-aos=""
          >
            <div
              className="text-left align-self-center justify-self-center  w-50 "
              data-aos="flip-up"
            >
              <p
                style={{
                  fontWeight: "400",
                  lineHeight: "1.5rem",
                  fontSize: "1rem",
                }}
              >
                Warehouses stand as the guardians of commerce, where the ebb and
                flow of goods become a symphony of efficiency and precision.
                Beyond the concrete and steel, these structures embody the
                intricate dance of supply meeting demand, weaving together the
                threads of production, storage, and distribution.
              </p>
            </div>
            <div
              className="d-flex flex-wrap justify-content-between align-items-center px-5 mt-5 mr-5 pr-3 simp-img"

              data-aos="slide-up"
            >
              <img lazy="true"  src={warehouse3} className="w-100 h-100" />
            </div>
          </div>

          <div
            className="d-flex flex-wrap justify-content-center align-items-center  w-100 px-5 mt-5"
            data-aos=""
          >
            <div
              className="d-flex flex-wrap justify-content-between align-items-center   mr-5 pr-3 simp-img"
             
              data-aos="slide-down"
            >
              <img lazy="true"  src={warehouse4} className="w-100 h-100" />
            </div>
            <div
              className="text-left align-self-center justify-self-center  w-50 "
              data-aos="flip-up"
            >
              <p
                style={{
                  fontWeight: "400",
                  lineHeight: "1.5rem",
                  fontSize: "1rem",
                }}
              >
                Warehouses are the silent keepers of a company's success, where
                every inventory holds a story of supply meeting demand.{" "}
              </p>
            </div>
          </div>
          <div
            className="d-flex flex-wrap justify-content-center align-items-center  w-100 pl-5 mt-5"
            data-aos=""
          >
            <div
              className="text-left align-self-center justify-self-center  w-50 "
              data-aos="flip-up"
            >
              <p
                style={{
                  fontWeight: "400",
                  lineHeight: "1.5rem",
                  fontSize: "1rem",
                }}
              >
                Within the walls of a warehouse lie the untold stories of
                countless products, each waiting for its moment to shine in the
                marketplace. It's not merely a storage space; it's a hub of
                aspirations and solutions, where the inventory embodies the
                aspirations of producers and the needs of consumers.
              </p>
            </div>
            <div
              className="d-flex flex-wrap justify-content-between align-items-center px-5 mt-5 mr-5 pr-3 simp-img"

              data-aos="slide-up"
            >
              <img lazy="true"  src={warehouse5} className="w-100 h-100" />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
