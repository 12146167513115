import React from "react";
import { footer } from "../assets/exports";
import { useNavigate } from "react-router-dom";

export function Footer() {
  const navigate = useNavigate()
  return <>
    <div class="parallax mb-0">
      <div class="parallax-overlay2 d-flex flex-wrap justify-content-center align-items-center">
        <div className="mt-5 pt-2 d-flex flex-wrap align-items-center justify-content-center mb-5 px-3">
          <div>
            <div
              className="w-75 text-left align-self-center justify-self-center fs-md-5 px-3"
              style={{
                maxWidth: "1200px",
                fontFamily: "Popins",
                fontWeight: "700",
                lineHeight: "1em",
                letterSpacing: "-0.04em",
                color: "white",
              }}
              data-aos="fade-down"
            >
              Get In Touch
            </div>
            <hr />
            <div
              className="d-flex justify-content-between"
              style={{ color: "white" }}
            >
              <h4 className="mb-3 mt-5 " data-aos="fade-up">
                Building Relationships, One Conversation at a Time
              </h4>
              <span
                className="d-flex align-items-center link-to-component"
                style={{
                  fontSize: "1.5rem",
                  fontFamily: "jost",
                  height: "2.5rem",
                }}
                onClick={()=>navigate("/contact")}
              >
                Contact Us{" "}
                <span
                  class="material-symbols-outlined"
                  style={{ fontSize: "2rem" }}
                >
                  north_east
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="" >
      <div
        className="position-absolute w-100 footer"
        style={{
          opacity: "0.9",
       
          zIndex: "200",
          backgroundColor: "white",
        }}
      ></div>
      <div
        className="m-0 h-100  row position-relative"
        style={{
          backgroundImage: `url(${footer})`,
          backgroundSize: "100% 600px ",
          backgroundPositionY: "400px",
          backgroundOrigin: "padding-box",
        }}
      >
        <div className="d-flex justify-content-between align-items-center w-100 h-100 p-3 flex-wrap" style={{ zIndex: "201" }}>
          <div
            className="d-flex flex-column align-items-center justify-content-center w-50"
            style={{ zIndex: "201" }}
          >
            <div
              onClick={()=>navigate("/")}
              className="logo footer-logo h-100 mt-5 font-md-6"
              style={{
                fontSize: "30px",
                fontWeight: "700",
                color: "black",
                opacity: "1",
                height:"5rem",
                width:"100%"
              }}
            >
              Dream Home Interior
            </div>
            <div className=" d-md-block d-sm-none w-50">
              <b>Address:</b> Plot no 10, sr no 657, Gangadham uppar road ,
              vardhman motor lane, Near jaquar sanitary park godown,
              opposite octopus advertising verticals,  pune 411037
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center h-100">
            <div>
              <b>Email Id : </b> admin@dreamhomeinterior.org.in
            </div>
            <div className="d-flex">
              <b>Phone Number : </b> 
              <div className="d-flex flex-column mx-2"> 
                <span>+91 9226768060 </span> 
                <span>+91 9561666910</span>
                <span>+91 8855048032</span> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
}
