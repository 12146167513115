import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderBase } from "../HeaderBase";
import { gallery10 } from "../../assets/exports";
import { Form, Button, Row, Container, Col } from "react-bootstrap";
import { getFromServer, postToServer } from "../../utils/AxiosCalls";
import { toast } from "react-toastify";
import { CircularProgressbar,buildStyles  } from 'react-circular-progressbar'; 
import 'react-circular-progressbar/dist/styles.css';
export const AddNewProject = () => {
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState({
    projectName: "",
    description: "",
    photos: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProjectData({ ...projectData, [name]: value });
  };

  const handlePhotoChange = (e) => {
    const files = e.target.files;
    setProjectData({ ...projectData, photos: files });
  };

  const handleSubmit =async (e) => {
    e.preventDefault();
    // You can handle the submission here
    try {
      if((projectData.projectName).trim()==""||(projectData.description).trim()==""||projectData.photos.length==0)
      {
          toast.error("Fields cannot be empty")
      }
      else{
        setProgress(1);
        const formData = new FormData();
        formData.append("name", projectData.projectName);
        formData.append("desc", projectData.description);
        formData.append("token",  sessionStorage.getItem("token"));
  
        formData.append(
          "projectid",
          Math.floor(Math.random() * (99999999 - 11111111 + 1)) + 1111111
        );
      
          for (let i = 0; i < projectData.photos.length; i++) {
            formData.append('images',  projectData.photos[i]);
          }
        
          const token  = sessionStorage.getItem("token")
        const resp = await postToServer("/api/auth",{token},{token},{headers:{token}})
        handleImageUpload(projectData.photos.length)
        if(resp.success==true)
        {
          const response =await postToServer(
            "/api/project/add",
           formData,
            {},
            {
              "Content-Type": "multipart/form-data",
              "token": sessionStorage.getItem("token")
            },
            navigate
          );
    
         if(response.success && response.success === true)
         {
            setProgress(100)
            toast.success("New project details added Successfully!!")
            setProjectData({
              projectName: "",
              description: "",
              photos: [],
            })
            setTimeout(()=>navigate("/admin/home"),3000)
         }
         else{
          toast.error("Something went wrong. Please try again!")
         }
        }

  
      }
 
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };
  const [progress, setProgress] = useState(0);

    const handleImageUpload = async (numImages=1) => {
      // Simulating image upload time
      const uploadTimeInSeconds = 40; // 3 minut
      // Calculate progress increment per second to reach 90% in 3 minutes
      const progressIncrement = ( 100) / (uploadTimeInSeconds * numImages);
      // Simulate image upload with delays
      while(progress<90)
      {
        await new Promise(resolve => setTimeout(resolve, 2000)); // Equal delay per image
  
        // Update progress
        if(progress!==100)
        {
          setProgress(prevProgress => Math.min(prevProgress + 2,100));

        }
      }
  
      // Reset progress after completion (you can adjust this based on your needs)
      setProgress(0);
    };
  


  return (
    <>
      <div style={{ height: "80vh", overflow: "hidden" }}>
        <div className="homepage-container mainpagenav-container">
          <div className="w-100 flex-wrap justify-content-center homepage-nav ">
            <HeaderBase navigate={navigate} />
          </div>
          <div
            className="mainpage-container position-relative"
            style={{ height: "80vh" }}
          >
            <img
              src={gallery10}
              style={{ height: "80vh", width: "100%" }}
              className=""
              text="Second slide"
              // data-aos="zoom-in"
            />
            <div
              className="position-absolute"
              style={{
                top: "50%",
                left: "35%",
                fontSize: "4rem",
                color: "white",
                fontWeight: "700",
                zIndex: "5555",
                fontFamily: "Jost",
              }}
            >
              Add Project
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center align-items-center">
        <div>
          <div className="mt-5 pt-5" style={{display:progress==0?"flex":"none"}}>
            <Container>
              <Row className="justify-content-md-center">
                <Form onSubmit={handleSubmit} style={{ color: "#073842" }}>
                  <Form.Group controlId="projectName" className="mb-3">
                    <Form.Label className="mt-2 fw-bolder ">
                      Project Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter project name"
                      name="projectName"
                      value={projectData.projectName}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="description" className="mb-3">
                    <Form.Label className="mt-2 fw-bolder ">
                      Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter description"
                      name="description"
                      value={projectData.description}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="photos" className="mb-3">
                    <Form.Label className="mt-2 fw-bolder ">
                      Upload Photos
                    </Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={handlePhotoChange}
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-3 mb-5 "
                    style={{ backgroundColor: "#073842" }}
                  >
                    Submit
                  </Button>
                </Form>
              </Row>
            </Container>
          </div>
          <div style={{display:progress>0?"flex":"none"}}>
          <div className="d-flex justify-content-center align-items-center flex-column mt-5 fw-bolder " style={{fontSize:"1.4rem"}}>
             Please Wait While Images are Uploaded
            <div className="mt-5" style={{width:"10rem"}}>
            <CircularProgressbar value={progress.toFixed(0)} text={`${progress.toFixed(0)}%`} />

            </div>

          </div>
          </div>

        </div>
      </div>
    </>
  );
};
