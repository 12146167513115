// EditModal.js
import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { postToServer } from '../../utils/AxiosCalls';
import { toast } from 'react-toastify';
// import './EditModal.css'; // Import a CSS file for custom styles

const EditModal = ({ showModal, handleClose, selectedItem,getInitdata }) => {
  const [name, setName] = useState('');
  const [desc, setDescription] = useState('');

  useEffect(() => {
    setName(selectedItem?.name);
    setDescription(selectedItem?.desc);
  }, [selectedItem]);

  const handleSaveChanges = async() => {
    // Handle saving changes (you can update state or make API calls here)

    try{
        const response =await postToServer(
            "/api/project/edit",
           {
            projectid:selectedItem._id,
            name,
            desc,
            "token": sessionStorage.getItem("token")
           },
            {},
            {
              "token": sessionStorage.getItem("token")
            })
            if(response.success === "true")
            {
                toast.success("Project Updates Successsfully")
            }
    handleClose();
    getInitdata()

    }catch(error){
        handleClose();

    }
    // Close the modal after saving changes
  };

  return (
    <Modal show={showModal} onHide={handleClose} size=''>
      <Modal.Header closeButton>
        <Modal.Title>Edit Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
        <Form.Group controlId="projectName" className="mb-3">
                    <Form.Label className="mt-2 fw-bolder ">
                      Project Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter project name"
                      name="projectName"
                      value={name}
                      onChange={(e)=>setName(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="description" className="mb-3">
                    <Form.Label className="mt-2 fw-bolder ">
                      Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter description"
                      name="description"
                      value={desc}
                      onChange={(e)=>setDescription(e.target.value)}
                    />
                  </Form.Group>
          <div className='mt-3 d-flex justify-content-end'>
        <Button variant="primary" onClick={handleSaveChanges} style={{backgroundColor:"#073842"}}>
          Save Changes
        </Button>
          </div>

        </Form>
      </Modal.Body>

    </Modal>
  );
};

export default EditModal;
