import React, { useEffect, useState } from "react";
import {
  aboutus1,
  aboutus2,
  aboutus3,
  aboutus4,
  aboutus5,
  aboutus6,
  aboutus7,
  aboutus8,
  aboutus9,
  cor1,
  cor2,
  cor3,
  cor4,
  cor5,
  footer,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  gallery09,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
  gallery15,
  homepageCorousal1,
  homepageCorousal2,
  homepageCorousal3,
  homepageCorousal4,
} from "../assets/exports";
import Carousel from "react-bootstrap/Carousel";
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Carousel as MyCustomCarousel } from "primereact/carousel";
import { TabView, TabPanel } from "primereact/tabview";
import AOS from "aos";
import "aos/dist/aos.css";

import NavDropdown from "react-bootstrap/NavDropdown";
import "./css/header.css";
// import { NavLink } from "react-router-dom";
import { Card, NavLink } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { Badge } from "primereact/badge";
import { Image } from "primereact/image";
import { Footer } from "./Footer";
import { HeaderBase } from "./HeaderBase";
import { getFromServer } from "../utils/AxiosCalls";
import { Button } from "primereact/button";
export const Projects = (props) => {
    useEffect(() => {
       
        window.scrollTo(0, 0);
      }, []);
  const navigate = useNavigate();
  const [clientNum, setClientNum] = useState({
    clients: 45,
    projects: 115,
    squareFeet: 230,
  });
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 500,
      easing: "ease-out-cubic",
    });
  }, []);
  const [timerId, setTimerId] = useState(0);
  const images = [
    { source: gallery01, alt: "Image 1" },
    { source: gallery02, alt: "Image 2" },
    { source: gallery03, alt: "Image 3" },
    { source: gallery04, alt: "Image 4" },
    { source: gallery05, alt: "Image 5" },
    { source: gallery06, alt: "Image 6" },
    { source: gallery07, alt: "Image 7" },
    { source: gallery08, alt: "Image 8" },
    { source: gallery09, alt: "Image 9" },
    { source: gallery10, alt: "Image 10" },
    { source: gallery11, alt: "Image 11" },
    { source: gallery12, alt: "Image 12" },
    { source: gallery13, alt: "Image 13" },
    { source: gallery14, alt: "Image 14" },
    { source: gallery15, alt: "Image 15" },
    // Add more images here
  ];

  const itemTemplate = (image) => {
    return (
      <div
        className="border-1 surface-border border-round m-2 text-center overflow-hidden"
        style={{ height: "300px", width: "330px" }}
      >
        <div className="">
          <Image  loading="lazy" 
            src={image.source}
            alt={image.alt}
            height="300px"
            width="330px"
            style={{ height: "300px", width: "330px" }}
            preview
          />
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (timerId == 0) {
      let timer = setInterval(() => {
        setClientNum((clientNum) => {
          setTimerId(1);
          if (clientNum.clients > 63) {
            clearInterval(timerId);
            return clientNum;
          } else {
            return {
              clients: clientNum?.clients + 1,
              projects: clientNum?.projects + 1,
              squareFeet: clientNum?.squareFeet + 1,
            };
          }
        });
        setTimerId(timer);
      }, 200);
      setTimerId(timer);
    }
  }, []);
  const [projectData, setProjectdata] = useState([]);
  useEffect(() => {
    (async () => {
      try{
        const resp = await getFromServer("/api/project/list",{},{},{},navigate);

        setProjectdata(resp)
      }
      catch(error)
      {console.log(error)}

    })();
  }, []);
  return (
    <>
      <div >
        <div className="w-100 d-flex flex-wrap justify-content-center homepage-container about-container project-container" style={{position:"fixed", zIndex:"9999"}}>
        <HeaderBase navigate={navigate}/>
        </div>

        <div  >
        <div className="mt-0 w-100 pt-2 d-flex flex-wrap align-items-center justify-content-center mb-5 w-100">
          <div className="d-flex flex-wrap justify-content-center align-items-center  w-100" style={{marginTop:"12rem"}}>
            <div
              className="text-left align-self-center justify-self-center fs-md-5 "
              style={{
                // maxWidth: "1200px",
                fontFamily: "Popins",
                fontWeight: "700",
                lineHeight: "1em",
                letterSpacing: "-0.04em",
                
              }}
              data-aos="flip-up"
            >
            Projects
          

            </div>

          </div>
        </div>
        <div className="mt-5 pt-5">
        <div className="d-flex justify-content-center align-items-center mb-5">
          <div className="d-flex flex-wrap justify-content-center">
            {projectData.map((item)=>{
              return <div className=" mb-5 d-flex flex-column" onClick={()=>navigate(`/projects/${item.projectid}`,{state:{...item}})}>
              <Card className=" mx-3" style={{ width: "18rem",height:"12rem", cursor:"pointer" }}>
            <Card.Img style={{height:"100%",width:"100%"}} variant="top" src={process.env.REACT_APP_BASEURL+"/api/images/"+item.images[0]} />
          </Card>

             <Card.Title className="text-center mt-3  fw-bolder" style={{color:"#073842",fontFamily:"Jost", fontSize:"1.5rem"}}>{item.name}</Card.Title>
          </div>
            })}

          </div>
        </div>
      </div>
        <Footer />
        </div>

      </div>
    </>
  );
};
