import React, { useState } from "react";
import "../css/login.css"
import { postToServer } from "../../utils/AxiosCalls";
import {toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
export const Login = () => {
    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")
    const navigate = useNavigate()
    const Login = async() =>{

        try{
            if(email.trim()==""||password.trim()=="")
            {
                toast.error("Email or Password cannot be empty")
            }
            else{
                const response = await postToServer("/api/login",{email,password},{},{},navigate)
                if(response.isAuth)
                {
                  sessionStorage.setItem("isAuth","true")
                  sessionStorage.setItem("token",response.token)
                  sessionStorage.setItem("data",response)


                    navigate("/admin/home")
    
                }
                else{
                    toast.error(response.message)
                }
            }

        }
        catch(error)
        {
            console.log(error)
            toast(error.message)
        }
    }
  return (
    <div className="login-container">
      <div class="login-sub-container" style={{height:"100vh",overflow:"hidden"}}>
        <div class="screen">
          <div class="screen__content">
            <div class="login">
              <div class="login__field">
                <i class="login__icon fas fa-user"></i>
                <input
                  type="text"
                  class="login__input"
                  placeholder="Email"
                  onChange={(e)=>setEmail(e.target.value)}
                />
              </div>
              <div class="login__field">
                <i class="login__icon fas fa-lock"></i>
                <input
                  type="password"
                  class="login__input"
                  placeholder="Password"
                  onChange={(e)=>setPassword(e.target.value)}
                />
              </div>
              <button class="button login__submit">
                <span class="button__text" onClick={(e)=>Login()}>Log In Now</span>
                <i class="button__icon fas fa-chevron-right"></i>
              </button>
            </div>
            <div class="social-login w-75">
              <h3 className="logo"> Dream Home Interiors</h3>
              <div class="social-icons">
                <a href="#" class="social-login__icon fab fa-instagram"></a>
                <a href="#" class="social-login__icon fab fa-facebook"></a>
                <a href="#" class="social-login__icon fab fa-twitter"></a>
              </div>
            </div>
          </div>
          <div class="screen__background">
            <span class="screen__background__shape screen__background__shape4"></span>
            <span class="screen__background__shape screen__background__shape3"></span>
            <span class="screen__background__shape screen__background__shape2"></span>
            <span class="screen__background__shape screen__background__shape1"></span>
          </div>
        </div>
      </div>
    </div>
  );
};
