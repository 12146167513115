import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Routes,
} from "react-router-dom";
import { Homepage } from "./Component/Homepage";
import { Header } from "./Component/Header";
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primeflex/primeflex.css"; // css utility
// import 'primeicons/primeicons.css';
import "primereact/resources/primereact.css";
import { PrimeReactProvider, PrimeReactContext } from "primereact/api";
import "aos/dist/aos.css";
import AOS from "aos";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { About } from "./Component/About";
import { Services } from "./Component/Services";
import { ContactUs } from "./Component/ContactUs";
import { Projects } from "./Component/Projects";
import ScrollToTop from "./Component/ScrollToTop";
import { Login } from "./Component/Admin/Login";
import { MainPage } from "./Component/Admin/MainPage";
import { PrivateRoutes } from "./utils/PrivateRoutes";
import { RestrictedRoutes } from "./utils/RestrictedRoutes";
import { AddNewProject } from "./Component/Admin/AddNewProject";
import { ProjectDetails } from "./Component/ProjectDetails";
import { Warehouse } from "./Component/Warehouse";

const App = () => {
  return (
    <PrimeReactProvider>
      <div style={{ minHeight: "100vh" }}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Header />
        
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/warehouse" element={<Warehouse />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/contact" element={<ContactUs />} />
          <Route exact path="/project" element={<Projects />} />

          <Route exact path="/projects/:projectid" element={<ProjectDetails/>}/>
          < Route element={<RestrictedRoutes/>}>

          <Route exact path="/login" element={<Login />} />
          </Route>
          < Route element={<PrivateRoutes/>}>
            <Route exact path="/admin/home" element={<MainPage />} />
            <Route exact path="/admin/project/add" element={<AddNewProject />} />
            

          </Route>
          <Route exact path="*" element={<Homepage />} />
        </Routes>
      </div>
    </PrimeReactProvider>
  );
};

export default App;
