
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'


export function RestrictedRoutes({children}) {

    const isAuth= sessionStorage.getItem("isAuth")
    // console.log(user)
    return isAuth?<Navigate to={'/admin/home'}/>:<Outlet/>
}
