import React, { useEffect, useState } from "react";
import {

  cor3,
  cor4,
  cor5,
  footer,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  gallery09,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
  gallery15,
  homepageCorousal1,
  homepageCorousal2,
  homepageCorousal3,
  homepageCorousal4,
} from "../assets/exports";
import Carousel from "react-bootstrap/Carousel";
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Carousel as MyCustomCarousel } from "primereact/carousel";
import { TabView, TabPanel } from "primereact/tabview";
import AOS from "aos";
import "aos/dist/aos.css";

import NavDropdown from "react-bootstrap/NavDropdown";
import "./css/header.css";
// import { NavLink } from "react-router-dom";
import { Button, Form, NavLink } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { Badge } from "primereact/badge";
import { Image } from "primereact/image";
import { Footer } from "./Footer";
import { InputText } from "primereact/inputtext";
import { HeaderBase } from "./HeaderBase";
import { postToServer } from "../utils/AxiosCalls";
import { toast } from "react-toastify";
export const ContactUs = (props) => {
    useEffect(() => {
       
        window.scrollTo(0, 0);
      }, []);
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 500,
      easing: "ease-out-cubic",
    });
  }, []);

  const [userData, setUserData] = useState({
    name:"",
    email:"",
    message:""
  })

  const sendMail = async() =>{
    try{

      const resp = await postToServer("/api/send/mail",{...userData},{},{},navigate)
      if(resp)
      {
        toast.success("Email sent.... we will connect with you soon! Thanks")
        setUserData({ name:"",
        email:"",
        message:""
      })
      }
    }
    catch(error)
    {
      console.log(error)
    }
  }
  return (
    <>
      <div className="homepage-container about-container">
        <div className="w-100 flex-wrap justify-content-center homepage-nav ">
        <HeaderBase navigate={navigate}/>
        </div>
        <div className="" style={{ height: "80vh" }}>
          <img
            src={cor3}
            style={{ height: "80vh", width: "100%" }}
            className=""
            text="Second slide"
            // data-aos="zoom-in"
          />
        </div>
        <div className="mt-0 w-100 pt-2 d-flex flex-wrap align-items-center justify-content-center mb-5 w-100">
          <div className="d-flex flex-wrap justify-content-center align-items-center  px-5 w-100 mt-5">
            <div
              className="text-left align-self-center justify-self-center fs-md-5 w-50 mt-5 mb-5"
              style={{
                // maxWidth: "1200px",
                fontFamily: "Popins",
                fontWeight: "700",
                lineHeight: "1em",
                letterSpacing: "-0.04em",
              }}
              data-aos="flip-up"
            >
              Let's Talk
              <hr className="mt-0 w-100" />
              <p
                style={{
                  letterSpacing: "0.1px",
                  fontWeight: "700",
                  lineHeight: "1.4em",
                  fontSize: "2rem",
                }}
              >
                Got a project on your mind? Let's discuss about the details.

                <div className="mt-5 pt-2"
                style={{
                    letterSpacing: "0.1px",
                    fontWeight: "700",
                    lineHeight: "1em",
                    fontSize: "1.2rem",
                    fontFamily:"Jost"
                  }}>
                Call Us 
                <div className="pt-3 pl-5 ml-5"
                style={{
                    letterSpacing: "0.1px",
                    fontWeight: "700",
                    lineHeight: "1em",
                    fontSize: "1.6rem",
                  }}>
                      <p>9226768060</p>
                    <p>9561666910</p>
                    <p>8855048032</p>
                  </div>
                  

              </div>
              </p>

            </div>
            <div
              className="d-flex flex-wrap justify-content-between align-items-center  w-50"
              data-aos="slide-down"
            >
              <div className="m-5 slidein w-100 d-md-block">
                <Form>
                  <Form.Group className="mt-4" controlId="formName">
                  
                    <Form.Control
                      type="text"
                      placeholder="Enter your name"
                      onChange={(e)=>setUserData(userData=>({...userData,name:e.target.value}))}
                      style={{ borderBottom: "1px solid #ced4da" }}
                      value={userData.name}
                    />
                  </Form.Group>

                  <Form.Group className="mt-4" controlId="formEmail">
                   
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      onChange={(e)=>setUserData(userData=>({...userData,email:e.target.value}))}
                      value={userData.email}
                      style={{ borderBottom: "1px solid #ced4da" }}
                    />
                  </Form.Group>

                  <Form.Group className="mt-4" controlId="formMessage">
                
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter your message"
                      onChange={(e)=>setUserData(userData=>({...userData,message:e.target.value}))}
                      value={userData.message}
                      style={{ borderBottom: "1px solid #ced4da" }}
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    onClick={()=>sendMail()}
                    className="mt-3"
                    style={{ borderBottom: "1px solid #ced4da",backgroundColor:"#073842" }}
                  >
                    Submit
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
