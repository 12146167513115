import axios from 'axios';
import { toast } from 'react-toastify';

const client = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});
let token = (sessionStorage.getItem('user'))
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.baseURL = process.env.REACT_APP_BASEURL
export const getFromServer = async (url,data={},params = {},headers={},navigate=()=>{}) => {
  // console.log(process.env.REACT_APP_BASEURL)
  try {
    const response = await axios.get(url,{params:{...data,token},headers:{...headers,token}});
    return response.data;
  } catch (error) {
    console.log(error)
    if (axios.isCancel(error)) {
      // console.log('Request cancelled', error.message);
    } else {
      if(error.code == "ERR_NETWORK")
      {
        toast.error("Something went wrong. Please try again!")
      }
     else if(error?.response?.status === 401)
      {
  
        sessionStorage.clear()
        navigate("/")
      }
      throw error;
    }
  }
};

export const postToServer = async (url,data={},params = {},headers={},navigate=()=>{}) => {
  try {
    const response = await client.post(url,data, {headers:{...headers,token},params:{token}});
    return response.data;
  } catch (error) {
    if(error.response.status === 401)
    {

      sessionStorage.clear()
      navigate("/")
    }
    throw error;
  }
};
