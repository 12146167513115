import React, { useEffect, useState } from "react";
import CustomCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  cor1,
  cor2,
  cor3,
  cor4,
  cor5,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  gallery09,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
  gallery15,
  homepageCorousal1,
  homepageCorousal2,
  homepageCorousal3,
  homepageCorousal4,
} from "../assets/exports";
import Carousel from "react-bootstrap/Carousel";
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Carousel as MyCustomCarousel } from "primereact/carousel";
import { TabView, TabPanel } from "primereact/tabview";
import AOS from "aos";
import "aos/dist/aos.css";

import NavDropdown from "react-bootstrap/NavDropdown";
import "./css/header.css";
// import { NavLink } from "react-router-dom";
import { NavLink } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { Badge } from "primereact/badge";
import { Image } from "primereact/image";
import { Footer } from "./Footer";
export const Homepage = (props) => {
  const navigate = useNavigate();
  const [clientNum, setClientNum] = useState({
    clients: 45,
    projects: 115,
    squareFeet: 230,
  });
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 500,
      easing: "ease-out-cubic",
    });
  }, []);
  const [timerId, setTimerId] = useState(0);
  const images = [
    { source: gallery01, alt: "Image 1" },
    { source: gallery02, alt: "Image 2" },
    { source: gallery03, alt: "Image 3" },
    { source: gallery04, alt: "Image 4" },
    { source: gallery05, alt: "Image 5" },
    { source: gallery06, alt: "Image 6" },
    { source: gallery07, alt: "Image 7" },
    { source: gallery08, alt: "Image 8" },
    { source: gallery09, alt: "Image 9" },
    { source: gallery10, alt: "Image 10" },
    { source: gallery11, alt: "Image 11" },
    { source: gallery12, alt: "Image 12" },
    { source: gallery13, alt: "Image 13" },
    { source: gallery14, alt: "Image 14" },
    { source: gallery15, alt: "Image 15" },
    // Add more images here
  ];

  const itemTemplate = (image) => {
    return (
      <div
        className="border-1 surface-border border-round m-2 text-center overflow-hidden"
        style={{ height: "300px", width: "330px" }}
      >
        <div className="">
          <Image
            loading="lazy"
            src={image}
            alt={image.alt}
            height="300px"
            width="330px"
            style={{ height: "300px", width: "330px" }}
            preview
          />
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (timerId == 0) {
      let timer = setInterval(() => {
        setClientNum((clientNum) => {
          setTimerId(1);
          if (clientNum.clients > 63) {
            clearInterval(timerId);
            return clientNum;
          } else {
            return {
              clients: clientNum?.clients + 1,
              projects: clientNum?.projects + 1,
              squareFeet: clientNum?.squareFeet + 1,
            };
          }
        });
        setTimerId(timer);
      }, 200);
      setTimerId(timer);
    }
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <>
      <div className="homepage-container">
        <div className="w-100 flex-wrap justify-content-center homepage-nav ">
          <Navbar
            collapseOnSelect
            expand="lg"
            className={"pt-0 pb-0 position-absolute "}
            style={{
              zIndex: "99999",
              width: "90%",
              height: "80px",
              borderBottom: "1px solid rgba(255, 255, 255, 0.25)",
              backdropFilter: "blur(16px)",
              boxShadow:
                "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            }}
          >
            <Container
              className="px-3 pt-0 h-100 ml-5 pl-5 "
              style={{
                maxWidth: "100%",
                height: "95px",
                backgroundColor: "transparent",
              }}
            >
              {/* box-shadow: ; */}
              <Navbar.Brand
                onClick={() => navigate("/")}
                className="logo h-100 d-flex flex-wrap align-items-center "
                style={{
                  fontSize: "30px",
                  paddingRight: "1rem",
                  fontWeight: "700",
                  borderRight: "1px solid rgba(255, 255, 255, 0.25)",
                }}
              >
                Dream Home Interior
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav
                  className="me-2 m position-relative nav-box pl-5 ml-5"
                  style={{
                    width: "fit-content",
                    fontWeight: "500",
                    minHeight: "80px",
                    color: "#222222!important",
                  }}
                >
                  <Nav.Link
                    className={
                      window.location.pathname === "/"
                        ? "nav-item active h-100"
                        : "nav-item h-100"
                    }
                  >
                    <NavLink
                      onClick={() => navigate("/")}
                      style={{ position: "relative", zIndex: "5" }}
                      className="pt-3"
                    >
                      {" "}
                      Home{" "}
                    </NavLink>
                  </Nav.Link>
                  <Nav.Link
                    className={
                      window.location.pathname === "/about"
                        ? "nav-item active h-100"
                        : "nav-item h-100"
                    }
                    onClick={() => navigate("/about")}
                  >
                    <NavLink
                      style={{ position: "relative", zIndex: "5" }}
                      className="pt-3"
                    >
                      {" "}
                      About Us{" "}
                    </NavLink>
                  </Nav.Link>
                  <Nav.Link
                    className={
                      window.location.pathname === "/warehouse"
                        ? "nav-item active h-100"
                        : "nav-item h-100"
                    }
                    onClick={() => navigate("/warehouse")}
                  >
                    <NavLink
                      style={{ position: "relative", zIndex: "5" }}
                      className="pt-3"
                    >
                      {" "}
                      Warehouse{" "}
                    </NavLink>
                  </Nav.Link>
                  <Nav.Link
                    className={
                      window.location.pathname === "/services"
                        ? "nav-item active h-100"
                        : "nav-item h-100"
                    }
                    onClick={() => navigate("/services")}
                  >
                    <NavLink
                      style={{ position: "relative", zIndex: "5" }}
                      className="pt-3"
                    >
                      {" "}
                      Services{" "}
                    </NavLink>
                  </Nav.Link>
                  <Nav.Link
                    className={
                      window.location.pathname === "/project"
                        ? "nav-item active h-100"
                        : "nav-item h-100"
                    }
                    onClick={() => navigate("/project")}
                  >
                    <NavLink
                      style={{ position: "relative", zIndex: "5" }}
                      className="pt-3"
                    >
                      {" "}
                      Project{" "}
                    </NavLink>
                  </Nav.Link>
                  {/* <Nav.Link  className={ window.location.pathname === '/home'?"nav-item active h-100":"nav-item h-100" }   onClick={()=>navigate("/")}   styl ><NavLink  e={{position:"relative",zIndex:"5"}}> News </NavLink></Nav.Link> */}
                  <Nav.Link
                    className={
                      window.location.pathname === "/contact"
                        ? "nav-item active h-100"
                        : "nav-item h-100 "
                    }
                    onClick={() => navigate("/contact")}
                  >
                    <NavLink
                      style={{ position: "relative", zIndex: "5" }}
                      className="pt-3"
                    >
                      {" "}
                      Contact{" "}
                    </NavLink>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>

        <div className="corusel" style={{ height: "100vh" }}>
          <Carousel>
            <Carousel.Item interval={100000} className="">
              <img
                src={homepageCorousal2}
                style={{ height: "101vh", width: "100%" }}
                className="zoom-image"
                text="Second slide"
              />
              <Carousel.Caption>
                <h3 className="cor-title">
                  Your Space, Your Signature Style!
                  <h6
                    className="mt-3"
                    style={{ fontFamily: "Popins", fontStyle: "Italic" }}
                  >
                    {"Raw To Ready, Everything at one Place !"}
                  </h6>
                </h3>
                <p className=" flex-wrap p-3 flex-grow align-items-center cor-sub-title">
                  <div
                    className=" w-75 "
                    style={{
                      borderRight: "1px solid rgba(32, 53, 86, 0.68)",
                      paddingRight: "1rem",
                      marginRight: "1rem",
                    }}
                  >
                    <div>
                      Transforming interior spaces in Pune with the art and science of design, 
                      Dream Home Interior creates functional and stylish living environments.
                    </div>
                  </div>
                  <div className="pl-5 cor-details">Next</div>
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={4000} className="">
              <img
                src={homepageCorousal4}
                style={{ height: "101vh", width: "100%" }}
                className="zoom-image"
                text="Third slide"
              />
              <Carousel.Caption>
                <h3 className="cor-title">Where Style Meets Comfort</h3>
                <p className=" flex-wrap p-3 flex-grow align-items-center cor-sub-title">
                  <div
                    className=" w-75 "
                    style={{
                      borderRight: "1px solid rgba(32, 53, 86, 0.68)",
                      paddingRight: "1rem",
                      marginRight: "1rem",
                    }}
                  >
                    <div>
                      Ultimately, in Pune, Dream Home Interior endeavors to craft environments through interior design
                      that elevate the quality of life, evoke specific moods, and evoke emotions in a space that feels both
                      functional and aesthetically pleasing
                    </div>
                  </div>
                  <div className="pl-5 cor-details">Next</div>
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={4000} className="">
              <img
                src={homepageCorousal1}
                style={{ height: "101vh", width: "100%" }}
                className="zoom-image"
                text="First slide"
              />
              <Carousel.Caption>
                <h3 className="cor-title">Discover the Art of Living</h3>
                <p className=" flex-wrap p-3 flex-grow align-items-center cor-sub-title">
                  <div
                    className=" w-75 "
                    style={{
                      borderRight: "1px solid rgba(32, 53, 86, 0.68)",
                      paddingRight: "1rem",
                      marginRight: "1rem",
                    }}
                  >
                    <div>
                      Interior design aims to create spaces that not only look
                      visually appealing but also enhance the well-being and
                      quality of life.
                    </div>
                  </div>
                  <div className="pl-5 cor-details">Next</div>
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={4000} className="">
              <img
                src={homepageCorousal3}
                style={{ height: "101vh", width: "100%" }}
                className="zoom-image"
                text="Third slide"
              />
              <Carousel.Caption>
                <h3 className="cor-title">Creating Harmony Through Design</h3>
                <p className="flex-wrap p-3 flex-grow align-items-center cor-sub-title">
                  <div
                    className=" w-75 "
                    style={{
                      borderRight: "1px solid rgba(32, 53, 86, 0.68)",
                      paddingRight: "1rem",
                      marginRight: "1rem",
                    }}
                  >
                    <div>
                      Interior design is the art and science of enhancing the
                      interior spaces of buildings to achieve a more functional.
                    </div>
                  </div>
                  <div className="pl-5 cor-details">Next</div>
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>

        <div className="mt-5 pt-2 d-flex flex-wrap align-items-center justify-content-center mb-5 px-3">
          <div>
            <div
              className="w-75 text-left align-self-center justify-self-center fs-md-5"
              style={{
                maxWidth: "1200px",
                fontFamily: "Popins",
                fontWeight: "700",
                lineHeight: "1em",
                letterSpacing: "-0.04em",
              }}
              data-aos="fade-down"
            >
              Elevate Your Interiors
            </div>
            <hr />
            <div>
              <h4 className="mb-3 mt-5 " data-aos="fade-up">
                Designing Interiors That Leave a Lasting Impression
              </h4>
              <div className="row mt-5  " data-aos="slide-up">
                <ul class=" col mt-2 " style={{ listStyle: "none" }}>
                  <li className="list-hmpg mt-2 " style={{ fontSize: "18px" }}>
                    <span classname="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                    </span>{" "}
                    Consultation
                  </li>
                  <li className="list-hmpg mt-2 " style={{ fontSize: "18px" }}>
                    <span classname="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                    </span>{" "}
                    Space Planning
                  </li>
                  <li className="list-hmpg mt-2 " style={{ fontSize: "18px" }}>
                    <span classname="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                    </span>{" "}
                    Concept Development
                  </li>
                </ul>
                <ul
                  class="services-list col mt-2 "
                  style={{ listStyle: "none" }}
                >
                  <li className="list-hmpg mt-2 " style={{ fontSize: "18px" }}>
                    <span classname="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                    </span>{" "}
                    Design Development
                  </li>
                  <li className="list-hmpg mt-2 " style={{ fontSize: "18px" }}>
                    <span classname="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                    </span>{" "}
                    3D Visualization
                  </li>
                  <li className="list-hmpg mt-2 " style={{ fontSize: "18px" }}>
                    <span classname="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                    </span>{" "}
                    Project Management
                  </li>
                </ul>
                <ul
                  class="services-list col mt-2 "
                  style={{ listStyle: "none" }}
                >
                  <li className="list-hmpg mt-2 " style={{ fontSize: "18px" }}>
                    <span classname="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                    </span>{" "}
                    Furniture Selection and Procurement
                  </li>
                  <li className="list-hmpg mt-2 " style={{ fontSize: "18px" }}>
                    <span classname="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                    </span>{" "}
                    Lighting Design
                  </li>
                  <li className="list-hmpg mt-2 " style={{ fontSize: "18px" }}>
                    <span classname="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-lg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                    </span>{" "}
                    Custom Design Elements
                  </li>
                </ul>
              </div>
              <div className="row" data-aos="slide-up">
                <div className="col d-flex flex-wrap flex-column ">
                  <div className="counters">{clientNum?.clients}</div>
                  <div>Clients Around The World</div>
                </div>
                <div className="col d-flex flex-wrap flex-column ">
                  <div className="counters">{clientNum?.projects}</div>
                  <div>Projects Completed</div>
                </div>
                <div className="col d-flex flex-wrap flex-column ">
                  <div className="counters">{clientNum?.squareFeet}K</div>
                  <div>Square Feet</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="parallax">
          <div class="parallax-overlay d-flex flex-wrap justify-content-center align-items-center">
            <marquee behavior="scroll" direction="left" scrollamount="15px">
              <span className="counters" style={{ color: "white" }}>
                Modern
                Structures&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <span className="counters" style={{ color: "white" }}>
                Decoration&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              &nbsp;&nbsp;
              <span className="counters" style={{ color: "white" }}>
                Thinking outside of the
                box&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              &nbsp;&nbsp;
              <span className="counters" style={{ color: "white" }}>
                Interior Design&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              &nbsp;&nbsp;
            </marquee>
            {/* <!-- Add more text elements or customize the text --> */}
          </div>
        </div>
        <div className="mt-5 w-100 pt-2 d-flex flex-wrap align-items-center justify-content-center mb-5 px-3">
          <div className="d-flex flex-wrap justify-content-center align-items-center flex-column w-75">
            <div
              className="w-75 text-left align-self-center justify-self-center fs-md-5 "
              style={{
                // maxWidth: "1200px",
                fontFamily: "Popins",
                fontWeight: "700",
                lineHeight: "1em",
                letterSpacing: "-0.04em",
              }}
              data-aos="slide-up"
            >
              About Our Design Philosophy
            </div>
            <hr className="mt-0 " />
            <div
              className="d-flex flex-wrap justify-content-between align-items-center  w-100"
              data-aos="slide-up"
            >
              <div className="m-5 slidein w-25 d-md-block d-sm-none">
                <div class="gallery mt-1 pt-5">
                  <img lazy="true" src={cor3} />
                  <img lazy="true" src={cor4} />
                  <img lazy="true" src={cor5} />
                </div>
              </div>
              <div
                className=" mr-5 bg-white slidein w-sm-100 w-md-100"
                style={{ fontFamily: "Jost", fontSize: "0.9rem" }}
              >
                <TabView>
                  <TabPanel header="History">
                    <p className="m-0">
                      At Dream Home Interior, we believe that every space has the potential
                      to be transformed into a work of art. With a passion for
                      design and a commitment to excellence, we strive to create
                      captivating interiors that reflect the unique
                      personalities and aspirations of our clients.Our team of
                      talented designers, architects, and craftsmen collaborates
                      closely with each client to understand their vision and
                      bring it to life.
                    </p>
                  </TabPanel>
                  <TabPanel header="Mission">
                    <p className="m-0">
                      Whether it's a residential project, commercial space, or a
                      hospitality establishment, we approach each project with
                      creativity, professionalism, and a commitment to
                      excellence. Our portfolio showcases a diverse range of
                      projects that highlight our versatility and ability to
                      adapt to different design styles and requirements.
                    </p>
                  </TabPanel>
                  <TabPanel header="Vision">
                    <p className="m-0">
                      What sets us apart is our attention to detail and our
                      commitment to delivering exceptional results. We
                      meticulously plan every aspect of the design process, from
                      space planning and furniture selection to material choices
                      and lighting design. Our goal is to create harmonious
                      environments that not only inspire but also enhance the
                      daily lives of those who inhabit them.
                    </p>
                  </TabPanel>
                </TabView>
                <div
                  className="mx-3 mb-2"
                  style={{ fontFamily: "Jost", fontSize: "0.9rem" }}
                >
                  <div className="d-flex flex-wrap justify-content-between w-100 mb-2">
                    <span>Space Planning and Layout</span>
                    <span> 95%</span>
                  </div>
                  <ProgressBar
                    value={95}
                    style={{ height: "3px" }}
                  ></ProgressBar>
                </div>
                <div
                  className="mx-3 mb-2"
                  style={{ fontFamily: "Jost", fontSize: "0.9rem" }}
                >
                  <div className="d-flex flex-wrap justify-content-between w-100 mb-2">
                    <span>Project Challenges and Solutions</span>
                    <span> 85%</span>
                  </div>
                  <ProgressBar
                    value={85}
                    style={{ height: "3px" }}
                  ></ProgressBar>
                </div>
                <div
                  className="mx-3 mb-4"
                  style={{ fontFamily: "Jost", fontSize: "0.9rem" }}
                >
                  <div className="d-flex flex-wrap justify-content-between w-100 mb-2">
                    <span>Sustainability and Eco-Friendly Features</span>
                    <span> 75%</span>
                  </div>
                  <ProgressBar
                    value={75}
                    style={{ height: "3px" }}
                  ></ProgressBar>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <section>
            <div className="mx-5 d-flex flex-wrap justify-content-center mt-2 ">
              <div className="row" style={{ maxWidth: "1800px" }}>
                <div className="col" data-aos="fade-up" data-aos-delay="500">
                  <div>
                    <span className="d-flex flex-wrap mb-4 w-100  justify-content-center">
                      <span class="material-symbols-outlined">globe_asia</span>
                      <Badge value="1"></Badge>
                    </span>
                    <h3
                      style={{
                        fontSize: "22px",
                        fontWeight: "600",
                        fontFamily: "Jost",
                      }}
                      className="mb-2 d-block"
                    >
                      Initial Consultation
                    </h3>
                    <p
                      style={{
                        fontSize: "0.9rem",
                        lineHeight: "1.4em",
                        fontFamily: "Jost",
                      }}
                    >
                      The process begins with an initial consultation where a
                      representative from Inspired Interiors meets with the
                      client to discuss their project requirements, goals, and
                      vision.
                    </p>
                  </div>
                </div>
                <div className="col" data-aos="fade-up" data-aos-delay="1000">
                  <div>
                    <span className="d-flex flex-wrap mb-4 w-100  justify-content-center">
                      <span class="material-symbols-outlined">
                        component_exchange
                      </span>
                      <Badge value="2"></Badge>
                    </span>
                    <h3
                      style={{
                        fontSize: "22px",
                        fontWeight: "600",
                        fontFamily: "Jost",
                      }}
                      className="mb-2 d-block"
                    >
                      Project Assessment and Space Analysis
                    </h3>
                    <p
                      style={{
                        fontSize: "0.9rem",
                        lineHeight: "1.4em",
                        fontFamily: "Jost",
                      }}
                    >
                      The design team conducts a thorough assessment of the
                      space, taking measurements, photographs, and gathering any
                      relevant architectural drawings or floor plans.
                    </p>
                  </div>
                </div>
                <div className="col" data-aos="fade-up" data-aos-delay="1500">
                  <div>
                    <span className="d-flex flex-wrap mb-4 w-100  justify-content-center">
                      <span class="material-symbols-outlined">cyclone</span>
                      <Badge value="3"></Badge>
                    </span>
                    <h3
                      style={{
                        fontSize: "22px",
                        fontWeight: "600",
                        fontFamily: "Jost",
                      }}
                      className="mb-2 d-block"
                    >
                      Design Concept Development
                    </h3>
                    <p
                      style={{
                        fontSize: "0.9rem",
                        lineHeight: "1.4em",
                        fontFamily: "Jost",
                      }}
                    >
                      Based on the client's preferences and the information
                      gathered during the assessment, Inspired Interiors
                      develops a design concept.
                    </p>
                  </div>
                </div>
                <div className="col" data-aos="fade-up" data-aos-delay="2000">
                  <div>
                    <span className="d-flex flex-wrap mb-4 w-100  justify-content-center">
                      <span class="material-symbols-outlined">
                        device_reset
                      </span>
                      <Badge value="4"></Badge>
                    </span>
                    <h3
                      style={{
                        fontSize: "22px",
                        fontWeight: "600",
                        fontFamily: "Jost",
                      }}
                      className="mb-2 d-block"
                    >
                      Design Presentation and Approval
                    </h3>
                    <p
                      style={{
                        fontSize: "0.9rem",
                        lineHeight: "1.4em",
                        fontFamily: "Jost",
                      }}
                    >
                      Inspired Interiors presents the design concept and space
                      plans to the client, utilizing visual aids such as 3D
                      renderings, sketches, and material samples.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="mt-3 mb-3" style={{ height: "350px" }}>
          <CustomCarousel
            className="custom-carousel"
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={10000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={""}
            slidesToSlide={1}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            <div>
              <Image
                loading="lazy"
                src={gallery01}
                alt="gallery01"
                height="300px"
                width="330px"
                style={{ height: "300px", width: "330px" }}
                preview
              />
            </div>

            {/* Image 2 */}
            <div>
              <Image
                loading="lazy"
                src={gallery02}
                alt="gallery02"
                height="300px"
                width="330px"
                style={{ height: "300px", width: "330px" }}
                preview
              />
            </div>

            {/* Image 3 */}
            <div>
              <Image
                loading="lazy"
                src={gallery03}
                alt="gallery03"
                height="300px"
                width="330px"
                style={{ height: "300px", width: "330px" }}
                preview
              />
            </div>

            {/* Image 4 */}
            <div>
              <Image
                loading="lazy"
                src={gallery04}
                alt="gallery04"
                height="300px"
                width="330px"
                style={{ height: "300px", width: "330px" }}
                preview
              />
            </div>

            {/* Image 5 */}
            <div>
              <Image
                loading="lazy"
                src={gallery05}
                alt="gallery05"
                height="300px"
                width="330px"
                style={{ height: "300px", width: "330px" }}
                preview
              />
            </div>

            {/* Image 6 */}
            <div>
              <Image
                loading="lazy"
                src={gallery06}
                alt="gallery06"
                height="300px"
                width="330px"
                style={{ height: "300px", width: "330px" }}
                preview
              />
            </div>

            {/* Image 7 */}
            <div>
              <Image
                loading="lazy"
                src={gallery07}
                alt="gallery07"
                height="300px"
                width="330px"
                style={{ height: "300px", width: "330px" }}
                preview
              />
            </div>

            {/* Image 8 */}
            <div>
              <Image
                loading="lazy"
                src={gallery08}
                alt="gallery08"
                height="300px"
                width="330px"
                style={{ height: "300px", width: "330px" }}
                preview
              />
            </div>

            {/* Image 9 */}
            <div>
              <Image
                loading="lazy"
                src={gallery09}
                alt="gallery09"
                height="300px"
                width="330px"
                style={{ height: "300px", width: "330px" }}
                preview
              />
            </div>

            {/* Image 10 */}
            <div>
              <Image
                loading="lazy"
                src={gallery10}
                alt="gallery10"
                height="300px"
                width="330px"
                style={{ height: "300px", width: "330px" }}
                preview
              />
            </div>

            {/* Image 11 */}
            <div>
              <Image
                loading="lazy"
                src={gallery11}
                alt="gallery11"
                height="300px"
                width="330px"
                style={{ height: "300px", width: "330px" }}
                preview
              />
            </div>

            {/* Image 12 */}
            <div>
              <Image
                loading="lazy"
                src={gallery12}
                alt="gallery12"
                height="300px"
                width="330px"
                style={{ height: "300px", width: "330px" }}
                preview
              />
            </div>

            {/* Image 13 */}
            <div>
              <Image
                loading="lazy"
                src={gallery13}
                alt="gallery13"
                height="300px"
                width="330px"
                style={{ height: "300px", width: "330px" }}
                preview
              />
            </div>

            {/* Image 14 */}
            <div>
              <Image
                loading="lazy"
                src={gallery14}
                alt="gallery14"
                height="300px"
                width="330px"
                style={{ height: "300px", width: "330px" }}
                preview
              />
            </div>

            {/* Image 15 */}
            <div>
              <Image
                loading="lazy"
                src={gallery15}
                alt="gallery15"
                height="300px"
                width="330px"
                style={{ height: "300px", width: "330px" }}
                preview
              />
            </div>
          </CustomCarousel>
          ;
        </div>

        <div className="mt-5 pt-2 d-flex flex-wrap align-items-center justify-content-center mb-5 px-3">
          <div>
            <div
              className="w-75 text-left align-self-center justify-self-center fs-md-5"
              style={{
                maxWidth: "1200px",
                fontFamily: "Popins",
                fontWeight: "700",
                lineHeight: "1em",
                letterSpacing: "-0.04em",
              }}
              data-aos="fade-down"
            >
              Project Execution
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <h4 className="mb-3 mt-5 " data-aos="fade-up">
                Designing the Extraordinary, Project by Project
              </h4>
              <span
                className="d-flex align-items-center link-to-component"
                style={{
                  fontSize: "1.5rem",
                  fontFamily: "jost",
                  height: "2.5rem",
                }}
                onClick={() => navigate("/project")}
              >
                Latest Projects{" "}
                <span
                  class="material-symbols-outlined"
                  style={{ fontSize: "2rem" }}
                >
                  north_east
                </span>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
