import React, { useEffect, useRef, useState } from "react";
import 'primeicons/primeicons.css';
import {
  aboutus1,
  aboutus2,
  aboutus3,
  aboutus4,
  aboutus5,
  aboutus6,
  aboutus7,
  aboutus8,
  aboutus9,
  cor1,
  cor2,
  cor3,
  cor4,
  cor5,
  footer,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  gallery09,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
  gallery15,
  homepageCorousal1,
  homepageCorousal2,
  homepageCorousal3,
  homepageCorousal4,
} from "../assets/exports";
import { Galleria } from 'primereact/galleria';
import { classNames } from 'primereact/utils';
import Carousel from "react-bootstrap/Carousel";
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Carousel as MyCustomCarousel } from "primereact/carousel";
import { TabView, TabPanel } from "primereact/tabview";
import AOS from "aos";
import "aos/dist/aos.css";
import "./css/galleri.css"
import NavDropdown from "react-bootstrap/NavDropdown";
import "./css/header.css";
// import { NavLink } from "react-router-dom";
import { Card, NavLink } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { Badge } from "primereact/badge";
import { Image } from "primereact/image";
import { Footer } from "./Footer";
import { HeaderBase } from "./HeaderBase";
import { Button } from "primereact/button";
export const ProjectDetails = (props) => {

    useEffect(() => {
       
        window.scrollTo(0, 0);
      }, []);
      const [images, setImages] = useState(null);
      const [activeIndex, setActiveIndex] = useState(0);
      const [showThumbnails, setShowThumbnails] = useState(false);
      const [isAutoPlayActive, setAutoPlayActive] = useState(true);
      const [isFullScreen, setFullScreen] = useState(false);
      
      const galleria = useRef(null)
      const navigate = useNavigate();
      const params = useLocation()
      const [projectDetails,setProjectDetails] = useState({})
      useEffect(()=>{
        setProjectDetails(params.state)
        if(params.state.images && params.state.images.length>0)
        {
            setImages((params.state.images).map((item)=>{
                return {src:`${process.env.REACT_APP_BASEURL + "/api/images/" + item}`}
            }))
        }
      },[params])
      const responsiveOptions = [
          {
              breakpoint: '1024px',
              numVisible: 5
          },
          {
              breakpoint: '960px',
              numVisible: 4
          },
          {
              breakpoint: '768px',
              numVisible: 3
          },
          {
              breakpoint: '560px',
              numVisible: 1
          }
      ];

  
      useEffect(() => {
          setAutoPlayActive(galleria.current.isAutoPlayActive())
      },[isAutoPlayActive]);
  
      const onItemChange = (event) => {
          setActiveIndex(event.index)
      }
  
      const toggleFullScreen = () => {
          if (isFullScreen) {
              closeFullScreen();
          }
          else {
              openFullScreen();
          }
      }
  
      const onFullScreenChange = () => {
          setFullScreen(prevState => !prevState )
      }
  
      const openFullScreen = () => {
          let elem = document.querySelector('.custom-galleria');
          if (elem.requestFullscreen) {
              elem.requestFullscreen();
          }
          else if (elem.mozRequestFullScreen) { /* Firefox */
              elem.mozRequestFullScreen();
          }
          else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
              elem.webkitRequestFullscreen();
          }
          else if (elem.msRequestFullscreen) { /* IE/Edge */
              elem.msRequestFullscreen();
          }
      }
  
      const closeFullScreen = () => {
          if (document.exitFullscreen) {
              document.exitFullscreen();
          }
          else if (document.mozCancelFullScreen) {
              document.mozCancelFullScreen();
          }
          else if (document.webkitExitFullscreen) {
              document.webkitExitFullscreen();
          }
          else if (document.msExitFullscreen) {
              document.msExitFullscreen();
          }
      }
  
      const bindDocumentListeners = () => {
          document.addEventListener("fullscreenchange", onFullScreenChange);
          document.addEventListener("mozfullscreenchange", onFullScreenChange);
          document.addEventListener("webkitfullscreenchange", onFullScreenChange);
          document.addEventListener("msfullscreenchange", onFullScreenChange);
      }
  
      const unbindDocumentListeners = () => {
          document.removeEventListener("fullscreenchange", onFullScreenChange);
          document.removeEventListener("mozfullscreenchange", onFullScreenChange);
          document.removeEventListener("webkitfullscreenchange", onFullScreenChange);
          document.removeEventListener("msfullscreenchange", onFullScreenChange);
      }
  
      const thumbnailTemplate = (item) => {
          return (
              <div className="grid grid-nogutter justify-content-center">
                  <img lazy="true"  src={item.src} style={{ display: 'block', height:"5rem", width:"7rem"}} />
              </div>
          );
      }
  
      const itemTemplate = (item) => {
          if (isFullScreen) {
              return <img lazy="true"  src={item.src} alt={item.alt} />
          }
  
          return <img lazy="true" className="galleria-image" src={item.src} alt={item.alt} style={{ display: 'block' }} />
      }
  
      const renderFooter = () => {
          let autoPlayClassName = classNames('pi', {
              'pi-play': !isAutoPlayActive,
              'pi-pause': isAutoPlayActive
          });
  
          let fullScreenClassName = classNames('pi', {
              'pi-window-maximize': !isFullScreen,
              'pi-window-minimize': isFullScreen
          });
  
          return (
              <div className="custom-galleria-footer">
                  <Button icon="pi pi-list" onClick={() => setShowThumbnails(prevState => !prevState)} />
                  <Button icon={autoPlayClassName} onClick={() => {
                      if (!isAutoPlayActive) {
                          galleria.current.startSlideShow();
                          setAutoPlayActive(true)
                      }
                      else {
                          galleria.current.stopSlideShow();
                          setAutoPlayActive(false)
                      }
                  }} />
                  {
                      images && (
                          <span className="title-container">
                              <span>{activeIndex + 1}/{images.length}</span>
                              <span className="title">{images[activeIndex].title}</span>
                              <span>{images[activeIndex].alt}</span>
                          </span>
                      )
                  }
                  <Button icon={fullScreenClassName} onClick={() => toggleFullScreen()} className="fullscreen-button" />
              </div>
          );
      }
  
      const footer = renderFooter();
      const galleriaClassName = classNames('custom-galleria', {
          'fullscreen': isFullScreen
      });
  

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 500,
      easing: "ease-out-cubic",
    });
  }, []);



  const [projectData, setProjectdata] = useState([]);

  return (
    <>
      <div className="homepage-container about-container">
        <div className="w-100 flex-wrap justify-content-center homepage-nav ">
          <HeaderBase navigate={navigate}/>
        </div>
        <div className="project-detail-super-image" style={{overflow:"hidden" }}>
          <img
            src={`${process.env.REACT_APP_BASEURL}/api/images/${params.state?.images[0]}`}
            style={{width: "100%",height:"100%" }}
            className=""
            text="Second slide"
            // data-aos="zoom-in"
          />
        </div>
        <div  >
            <div></div>
        <div className="mt-0 w-100 d-flex flex-wrap align-items-center justify-content-center mb-5 w-100">
          <div className="d-flex flex-wrap justify-content-center align-items-center  w-100" style={{marginTop:"8rem"}}>
            <div
              className="text-left align-self-center justify-self-center fs-md-3 "
              style={{
                // maxWidth: "1200px",
                fontFamily: "Popins",
                fontWeight: "700",
                lineHeight: "1em",
                letterSpacing: "-0.04em",
                fontSize:"3rem"
              }}
              data-aos="flip-up"
            >
            {projectDetails?.name}
          
            </div>

          </div>
        </div>
        <div className="mt-5 pt-5 mb-5">
        <div className="d-flex justify-content-center align-items-center mb-5">
          <div className="d-flex flex-wrap">
          <div
            className=" w-100 px-8"
            style={{ fontFamily: "Jost", fontSize: "1rem", lineHeight: "2rem" }}
            data-aos="slide-down"
          >
            {projectDetails.desc}
          </div>
              <div className="mx-5 pb-5 mt-5 pt-5 mt-5 mb-5 d-flex justify-content-center mb-5 w-100" style={{maxHeight:"120vh"}}>
              <div className="card galleria-demo">
            <Galleria ref={galleria} value={images} activeIndex={activeIndex} onItemChange={onItemChange}
            
                showThumbnails={showThumbnails} showItemNavigators showItemNavigatorsOnHover
                numVisible={5} circular autoPlay transitionInterval={3000} responsiveOptions={responsiveOptions}
                item={itemTemplate} thumbnail={thumbnailTemplate} footer={footer}
                className={galleriaClassName} />
        </div>              </div>
          </div>
        </div>
      </div>

        <Footer />
        </div>
      </div>
    </>
  );
};
