import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./css/header.css";
import { NavLink } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export function Header() {
  const navigate = useNavigate();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setVisible(currentScrollPos > 220);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const handleNavLinkClick = () => {
    // Close the navbar when a link is clicked
    const navbarToggle = document.querySelector('.navbar-toggler');
    if (navbarToggle) {
      navbarToggle.click();
    }
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={visible && window.location.pathname !== '/project' ? 'site-header pt-0 pb-0 h-100' : 'site-header hidden-md pt-0 pb-0 h-100 hidden-sm-none'}
      style={{
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"
      }}
    >
      <Container className="w-100 px-3 pt-0 h-100 ml-5 pl-5" style={{ maxWidth: "100%", height: "65px", backgroundColor: "#ffffff" }}>
        <Navbar.Brand
          className="logo h-100"
          style={{ fontSize: "30px", fontWeight: "700" }}
          onClick={() => navigate("/")}
        >
          Dream Home Interior
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className="me-2 m position-relative nav-box pl-5 ml-5"
            style={{ width: "fit-content", fontWeight: "500", color: "#222222!important" }}
          >
            <Nav.Link
              className={window.location.pathname === '/' ? "nav-item active h-100" : "nav-item h-100"}
              onClick={() => {
                navigate("/");
                handleNavLinkClick(); // Close navbar on click
              }}
            >
              <NavLink className={"nav-link"} style={{ position: "relative", zIndex: "5" }}> Home </NavLink>
            </Nav.Link>
            <Nav.Link
              className={window.location.pathname === '/about' ? "nav-item active h-100" : "nav-item h-100"}
              onClick={() => {
                navigate("/about");
                handleNavLinkClick(); // Close navbar on click
              }}
            >
              <NavLink style={{ position: "relative", zIndex: "5" }}> About Us </NavLink>
            </Nav.Link>
            <Nav.Link
              className={window.location.pathname === '/warehouse' ? "nav-item active h-100" : "nav-item h-100"}
              onClick={() => {
                navigate("/warehouse");
                handleNavLinkClick(); // Close navbar on click
              }}
            >
              <NavLink style={{ position: "relative", zIndex: "5" }}> Warehouse </NavLink>
            </Nav.Link>
            <Nav.Link
              className={window.location.pathname === '/services' ? "nav-item active h-100" : "nav-item h-100"}
              onClick={() => {
                navigate("/services");
                handleNavLinkClick(); // Close navbar on click
              }}
            >
              <NavLink style={{ position: "relative", zIndex: "5" }}> Services </NavLink>
            </Nav.Link>
            <Nav.Link
              className={window.location.pathname === '/project' ? "nav-item active h-100" : "nav-item h-100"}
              onClick={() => {
                navigate("/project");
                handleNavLinkClick(); // Close navbar on click
              }}
            >
              <NavLink style={{ position: "relative", zIndex: "5" }}> Project </NavLink>
            </Nav.Link>
            <Nav.Link
              className={window.location.pathname === '/contact' ? "nav-item active h-100" : "nav-item h-100 "}
              onClick={() => {
                navigate("/contact");
                handleNavLinkClick(); // Close navbar on click
              }}
            >
              <NavLink style={{ position: "relative", zIndex: "5" }}> Contact </NavLink>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
