import React, { useEffect, useState } from "react";
import {
  aboutus1,
  aboutus2,
  aboutus3,
  aboutus4,
  aboutus5,
  aboutus6,
  aboutus7,
  aboutus8,
  aboutus9,
  cor1,
  cor2,
  cor3,
  cor4,
  cor5,
  footer,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  gallery09,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
  gallery15,
  homepageCorousal1,
  homepageCorousal2,
  homepageCorousal3,
  homepageCorousal4,
} from "../assets/exports";
import Carousel from "react-bootstrap/Carousel";
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import { Carousel as MyCustomCarousel } from "primereact/carousel";
import { TabView, TabPanel } from "primereact/tabview";
import AOS from "aos";
import "aos/dist/aos.css";

import NavDropdown from "react-bootstrap/NavDropdown";
import "./css/header.css";
// import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { Badge } from "primereact/badge";
import { Image } from "primereact/image";
import { Footer } from "./Footer";
import { HeaderBase } from "./HeaderBase";
export const About = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [clientNum, setClientNum] = useState({
    clients: 45,
    projects: 115,
    squareFeet: 230,
  });
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 500,
      easing: "ease-out-cubic",
    });
  }, []);
  const [timerId, setTimerId] = useState(0);
  const images = [
    { source: gallery01, alt: "Image 1" },
    { source: gallery02, alt: "Image 2" },
    { source: gallery03, alt: "Image 3" },
    { source: gallery04, alt: "Image 4" },
    { source: gallery05, alt: "Image 5" },
    { source: gallery06, alt: "Image 6" },
    { source: gallery07, alt: "Image 7" },
    { source: gallery08, alt: "Image 8" },
    { source: gallery09, alt: "Image 9" },
    { source: gallery10, alt: "Image 10" },
    { source: gallery11, alt: "Image 11" },
    { source: gallery12, alt: "Image 12" },
    { source: gallery13, alt: "Image 13" },
    { source: gallery14, alt: "Image 14" },
    { source: gallery15, alt: "Image 15" },
    // Add more images here
  ];

  const itemTemplate = (image) => {
    return (
      <div
        className="border-1 surface-border border-round m-2 text-center overflow-hidden"
        style={{ height: "300px", width: "330px" }}
      >
        <div className="">
          <Image
            loading="lazy"
            src={image.source}
            alt={image.alt}
            height="300px"
            width="330px"
            style={{ height: "300px", width: "330px" }}
            preview
          />
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (timerId == 0) {
      let timer = setInterval(() => {
        setClientNum((clientNum) => {
          setTimerId(1);
          if (clientNum.clients > 63) {
            clearInterval(timerId);
            return clientNum;
          } else {
            return {
              clients: clientNum?.clients + 1,
              projects: clientNum?.projects + 1,
              squareFeet: clientNum?.squareFeet + 1,
            };
          }
        });
        setTimerId(timer);
      }, 200);
      setTimerId(timer);
    }
  }, []);
  return (
    <>
      <div className="homepage-container about-container">
        <div className="w-100 flex-wrap justify-content-center homepage-nav ">
          <HeaderBase navigate={navigate} />
        </div>
        <div className="" style={{ height: "80vh" }}>
          <img
            src={homepageCorousal1}
            style={{ height: "80vh", width: "100%" }}
            className=""
            text="Second slide"
            // data-aos="zoom-in"
          />
        </div>
        <div className="mt-0 w-100 pt-2 d-flex flex-wrap align-items-center justify-content-center mb-5 w-100">
          <div className="d-flex flex-wrap justify-content-center align-items-center  w-100">
            <div
              className="text-left align-self-center justify-self-center fs-md-5 w-50 "
              style={{
                // maxWidth: "1200px",
                fontFamily: "Popins",
                fontWeight: "700",
                lineHeight: "1em",
                letterSpacing: "-0.04em",
              }}
              data-aos="flip-up"
            >
              About Us
              <hr className="mt-0 w-75" />
              <p
                style={{
                  letterSpacing: "0.1px",
                  fontWeight: "700",
                  lineHeight: "1em",
                  fontSize: "2rem",
                }}
              >
                Where Imagination Meets Interiors
              </p>
            </div>
            <div
              className="d-flex flex-wrap justify-content-between align-items-center  "
              data-aos="slide-down"
            >
              <div className="m-5 slidein w-25 d-md-block d-sm-none">
                <div class="gallery mt-1 pt-5">
                  <img lazy="true" src={cor3} />
                  <img lazy="true" src={cor4} />
                  <img lazy="true" src={cor5} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-wrap align-items-center justify-content-center"
          style={{ fontFamily: "Jost", fontSize: "1rem", lineHeight: "2rem" }}
        >
          <div
            className=" w-100 px-8"
            style={{ fontFamily: "Jost", fontSize: "1rem", lineHeight: "2rem" }}
            data-aos="slide-down"
          >
            At Dream Home Interior, our journey began amid the whispers of
            creativity and the steady rhythm of challenges. For over 15 years,
            we've navigated the intricate world of interior design, sculpting
            spaces that echo stories and embody dreams. Our narrative isn’t one
            of smooth paths but a tapestry woven from diverse experiences and
            labyrinthine challenges. We've danced with complexity, finding
            elegance in the unexpected and beauty in the face of trials. From
            reimagining cozy homes to orchestrating the transformation of
            sprawling corporate landscapes, we’ve embraced each project as a
            unique canvas, weaving aspirations and functionality seamlessly
            together. Our designers aren't just artisans; they're visionaries,
            adept at turning constraints into catalysts for innovation. Through
            the maze of projects—some grand, some intimate—we've honed our
            craft, learning the language of spaces and the art of harmonizing
            functionality with aesthetics.
          </div>
        </div>
        <div className="mt-5 w-100 pt-2 d-flex flex-wrap align-items-center justify-content-center mb-5 px-5 ">
          <div
            className="d-flex flex-wrap justify-content-center align-items-center  w-100 px-5 mt-5"
            data-aos=""
          >
            <div
              className="text-left align-self-center justify-self-center  w-50 "
              data-aos="flip-up"
            >
              <p
                style={{
                  fontWeight: "400",
                  lineHeight: "1.5rem",
                  fontSize: "1rem",
                }}
              >
                Through the maze of projects—some grand, some intimate—we've
                honed our craft, learning the language of spaces and the art of
                harmonizing functionality with aesthetics. We've navigated the
                intricate dance of client aspirations, architectural nuances,
                and logistical complexities with finesse, breathing life into
                spaces that resonate with individuality.
              </p>
            </div>
            <div
              className="d-flex flex-wrap justify-content-between align-items-center ml-5 pl-3 simp-img"
              data-aos="slide-down"
            >
              <img lazy="true" src={aboutus1} className="w-100 h-100" />
            </div>
          </div>
          <div
            className="d-flex flex-wrap justify-content-center align-items-center  w-100 px-5 mt-5"
            data-aos=""
          >
            <div
              className="d-flex flex-wrap justify-content-between align-items-center   mr-5 pr-3 simp-img"
              data-aos="slide-down"
            >
              <img lazy="true" src={aboutus2} className="w-100 h-100" />
            </div>
            <div
              className="text-left align-self-center justify-self-center  w-50 "
              data-aos="flip-up"
            >
              <p
                style={{
                  fontWeight: "400",
                  lineHeight: "1.5rem",
                  fontSize: "1rem",
                }}
              >
                What sets Dream Home Interior apart isn’t just our portfolio
                adorned with diverse projects but our ability to blend
                experience with adaptability. Challenges don't deter us; they
                fuel our creativity. The seemingly impossible becomes a canvas
                for our ingenuity.
              </p>
            </div>
          </div>
          <div
            className="d-flex flex-wrap justify-content-center align-items-center  w-100 pl-5 mt-5"
            data-aos=""
          >
            <div
              className="text-left align-self-center justify-self-center  w-50 "
              data-aos="flip-up"
            >
              <p
                style={{
                  fontWeight: "400",
                  lineHeight: "1.5rem",
                  fontSize: "1rem",
                }}
              >
                In a world of trends and fleeting styles, Dream Home Interior
                stands firm on the pillars of timelessness and innovation. We
                don’t just design; we curate experiences. Each stroke of our
                designs encapsulates a story, a feeling, a moment waiting to be
                lived.
              </p>
            </div>
            <div
              className="d-flex flex-wrap justify-content-between align-items-center px-5 mt-5 mr-5 pr-3 simp-img"
              data-aos="slide-up"
            >
              <img lazy="true" src={aboutus3} className="w-100 h-100" />
            </div>
          </div>
        </div>
        <div className="mt-5 pt-2 d-flex flex-wrap align-items-center justify-content-center mb-5 pb-5 mx-5 px-3">
          <div>
            <div
              className="w-75 text-left align-self-center justify-self-center fs-md-5"
              style={{
                maxWidth: "1200px",
                fontFamily: "Popins",
                fontWeight: "700",
                lineHeight: "1em",
                // letterSpacing: "-0.04em",
              }}
              data-aos="fade-down"
            >
              Showcase
              <hr />
              <div>
                <h4 className="mb-3 mt-5 fw-bolder" data-aos="fade-up">
                  Brings Vision to Reality
                </h4>
              </div>
            </div>
            <div className="d-flex  flex-wrap justify-content-evenly">
              <div
                className="mt-5"
                style={{ borderRadius: "5px", overflow: "hidden" }}
                data-aos="zoom-in"
              >
                <Image
                  loading="lazy"
                  src={aboutus4}
                  alt={""}
                  height="300px"
                  width="330px"
                  style={{ height: "300px", width: "330px" }}
                  preview
                />
              </div>
              <div
                className="mt-5"
                style={{ borderRadius: "5px", overflow: "hidden" }}
                data-aos="zoom-in"
              >
                <Image
                  loading="lazy"
                  src={aboutus5}
                  alt={""}
                  height="300px"
                  width="330px"
                  style={{ height: "300px", width: "330px" }}
                  preview
                />
              </div>
              <div
                className="mt-5"
                style={{ borderRadius: "5px", overflow: "hidden" }}
                data-aos="zoom-in"
              >
                <Image
                  loading="lazy"
                  src={aboutus6}
                  alt={""}
                  height="300px"
                  width="330px"
                  style={{ height: "300px", width: "330px" }}
                  preview
                />
              </div>
              <div
                className="mt-5"
                style={{ borderRadius: "5px", overflow: "hidden" }}
                data-aos="zoom-in"
              >
                <Image
                  loading="lazy"
                  src={aboutus7}
                  alt={""}
                  height="300px"
                  width="330px"
                  style={{ height: "300px", width: "330px" }}
                  preview
                />
              </div>
              <div
                className="mt-5"
                style={{ borderRadius: "5px", overflow: "hidden" }}
                data-aos="zoom-in"
              >
                <Image
                  loading="lazy"
                  src={aboutus8}
                  alt={""}
                  height="300px"
                  width="330px"
                  style={{ height: "300px", width: "330px" }}
                  preview
                />
              </div>
              <div
                className="mt-5"
                style={{ borderRadius: "5px", overflow: "hidden" }}
                data-aos="zoom-in"
              >
                <Image
                  loading="lazy"
                  src={aboutus9}
                  alt={""}
                  height="300px"
                  width="330px"
                  style={{ height: "300px", width: "330px" }}
                  preview
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-0 w-100 pt-2 d-flex flex-wrap align-items-center justify-content-center mb-5 w-100">
          <div className="d-flex flex-wrap justify-content-center align-items-center  w-100">
            <div
              className="text-left align-self-center justify-self-center fs-md-5 w-50 "
              style={{
                // maxWidth: "1200px",
                fontFamily: "Popins",
                fontWeight: "700",
                lineHeight: "1em",
                letterSpacing: "-0.04em",
              }}
              data-aos="flip-up"
            >
              Why Us ?
            </div>
            <hr className="mt-0 w-75" />
            <div
              className="d-flex flex-wrap align-items-center justify-content-center mt-5"
              style={{
                fontFamily: "Jost",
                fontSize: "1rem",
                lineHeight: "2rem",
              }}
            >
              <div className=" w-100 px-8" data-aos="slide-down">
                <p>
                  We dream home interior are not just interior designing studio.
                  we are also supplier, (wholesaler and retailer) of raw
                  materials such as plywood, Laminates, and veneers and
                  Furniture fittings we also have our own Sofa manufacturing
                  factory so we don’t need to buy Raw materials or couches from
                  somewhere else. At dream, Home Interior, we got raw to ready
                  everything under one roof, which automatically saves 10 to 15%
                  cost that other interior designing firm and interior designers
                  pay to suppliers and Sofa manufacturers. This enables us to do
                  better quality work than any other interior designing studio
                  and firm in less budget.
                </p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
