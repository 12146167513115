import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-bootstrap";

export function HeaderBase({navigate}) {
  return <Navbar
    collapseOnSelect
    expand="lg"
    className={"pt-0 pb-0 position-absolute "}
    style={{
      zIndex: "99999",
      width: "90%",
      height: "80px",
      borderBottom: "1px solid rgba(255, 255, 255, 0.25)",
      backdropFilter: "blur(16px)",
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    }}
  >
    <Container
      className="px-3 pt-0 h-100 ml-5 pl-5 "
      style={{
        maxWidth: "100%",
        height: "95px",
        backgroundColor: "transparent",
      }}
    >
      {/* box-shadow: ; */}
      <Navbar.Brand
          onClick={()=>navigate("/")}
        className="logo h-100 d-flex flex-wrap align-items-center "
        style={{
          fontSize: "30px",
          paddingRight: "1rem",
          fontWeight: "700",
          borderRight: "1px solid rgba(255, 255, 255, 0.25)",
        }}
      >
        Dream Home Interior
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav
          className="me-2 m position-relative nav-box pl-5 ml-5"
          style={{
            width: "fit-content",
            fontWeight: "500",
            minHeight: "80px",
            color: "#222222!important",
          }}
        >
          <Nav.Link
            className={window.location.pathname === "/"
              ? "nav-item active h-100"
              : "nav-item h-100"}
          >
            <NavLink
              onClick={() => navigate("/")}
              style={{ position: "relative", zIndex: "5" }}
              className="pt-3"
            >
              {" "}
              Home{" "}
            </NavLink>
          </Nav.Link>
          <Nav.Link
            className={window.location.pathname === "/about"
              ? "nav-item active h-100"
              : "nav-item h-100"}
            onClick={() => navigate("/about")}
          >
            <NavLink
              style={{ position: "relative", zIndex: "5" }}
              className="pt-3"
            >
              {" "}
              About Us{" "}
            </NavLink>
          </Nav.Link>
          <Nav.Link
            className={window.location.pathname === "/warehouse"
              ? "nav-item active h-100"
              : "nav-item h-100"}
            onClick={() => navigate("/warehouse")}
          >
            <NavLink
              style={{ position: "relative", zIndex: "5" }}
              className="pt-3"
            >
              {" "}
              Warehouse{" "}
            </NavLink>
          </Nav.Link>
          <Nav.Link
            className={window.location.pathname === "/services"
              ? "nav-item active h-100"
              : "nav-item h-100"}
            onClick={() => navigate("/services")}
          >
            <NavLink
              style={{ position: "relative", zIndex: "5" }}
              className="pt-3"
            >
              {" "}
              Services{" "}
            </NavLink>

          </Nav.Link>
          <Nav.Link
            className={window.location.pathname === "/project"
              ? "nav-item active h-100"
              : "nav-item h-100"}
            onClick={() => navigate("/project")}
          >
            <NavLink
              style={{ position: "relative", zIndex: "5" }}
              className="pt-3"
            >
              {" "}
              Project{" "}
            </NavLink>
          </Nav.Link>
          {/* <Nav.Link  className={ window.location.pathname === '/home'?"nav-item active h-100":"nav-item h-100" }   onClick={()=>navigate("/")}   styl ><NavLink  e={{position:"relative",zIndex:"5"}}> News </NavLink></Nav.Link> */}
          <Nav.Link
            className={window.location.pathname === "/contact"
              ? "nav-item active h-100"
              : "nav-item h-100 "}
            onClick={() => navigate("/contact")}
          >
            <NavLink
              style={{ position: "relative", zIndex: "5" }}
              className="pt-3"
            >
              {" "}
              Contact{" "}
            </NavLink>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>;
}
