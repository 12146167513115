import React, { useEffect, useRef, useState } from "react";
import { HeaderBase } from "../HeaderBase";
import { useNavigate } from "react-router-dom";
import {
  aboutus1,
  gallery01,
  homepageCorousal1,
  homepageCorousal4,
} from "../../assets/exports";
import { Avatar } from "primereact/avatar";
import { getFromServer, postToServer } from "../../utils/AxiosCalls";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import EditModal from "./EditModal";
export const MainPage = () => {
  const navigate = useNavigate();
  const [projectData, setProjectdata] = useState([]);
  useEffect(() => {
    getInitdata();
  }, []);
  const [hoveredItem, setHoveredItem] = useState();
  const buttonEl = useRef();
  const [selectedItem, setSelectedItem] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const confirm2 = (projectid) => {
    confirmDialog({
      message: "Do you want to delete this record?",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };
  async function getInitdata() {
    const resp = await getFromServer("/api/project/list", {}, {}, {}, navigate);
    setProjectdata(resp);
  }
  const accept = async (projectid) => {
    try {
      setSelectedItem(async (selectedItem) => {
        const response = await postToServer(
          "/api/project/delete",
          {
            projectid: selectedItem.projectid,
            token: sessionStorage.getItem("token"),
          },
          {},
          {
            token: sessionStorage.getItem("token"),
          },
          navigate
        );
        if (response.success && response.success == true) {
          setProjectdata(response.projects);
          toast.success("Successfully Deleted the project.");
        } else {
          toast.error("Could not delete. Please try after refresh!!");
        }
      });
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong. Please Try again later!");
    }
  };
  const reject = () => {};
  const Logout = async () => {
    try {
      const resp = await postToServer("/api/logout",{ "token": sessionStorage.getItem("token")},);
      sessionStorage.clear();
      navigate("/");
    } catch (error) {
      console.log(error);
      sessionStorage.clear();
      navigate("/");
    }
  };
  return (
    <>
      <ConfirmDialog />
      <EditModal
        showModal={editItem !== null}
        handleClose={() => setEditItem(null)}
        selectedItem={editItem}
        getInitdata={getInitdata}
      />

      <div style={{ height: "80vh", overflow: "hidden" }}>
        <div className="homepage-container mainpagenav-container">
          <div className="w-100 flex-wrap justify-content-center homepage-nav ">
            <HeaderBase navigate={navigate} />
          </div>
          <div
            className="mainpage-container position-relative"
            style={{ height: "80vh" }}
          >
            <img
              src={aboutus1}
              style={{ height: "80vh", width: "100%" }}
              className=""
              text="Second slide"
              // data-aos="zoom-in"
            />
            <div
              className="position-absolute"
              style={{
                top: "50%",
                left: "35%",
                fontSize: "4rem",
                color: "white",
                fontWeight: "700",
                zIndex: "5555",
                fontFamily: "Jost",
              }}
            >
              Admin Pannel
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-between align-items-center">
        <button
          style={{ backgroundColor: "#073842" }}
          className="mt-5 ml-5 p-2 text-align-center d-flex justify-content-center fw-bolder cursor-pointer btn btn-primary"
          onClick={() => {
            navigate("/admin/project/add");
          }}
        >
          Add New Project
        </button>

        <div className="px-5 cursor-pointer logout">
          <DropdownButton
            id="dropdown-basic-button"
            style={{ backgroundColor: "transparent" }}
            title={
              <Avatar
                icon="pi pi-user"
                size=""
                style={{ backgroundColor: "#073842", color: "#ffffff" }}
                shape="circle"
              />
            }
          >
            <Dropdown.Item onClick={() => Logout()}>Logout</Dropdown.Item>
          </DropdownButton>
        </div>
      </div>

      <div className="mt-5 pt-5">
        <div className="d-flex justify-content-center align-items-center">
          <div className="d-flex flex-wrap justify-content-center">
            {projectData.map((item, index) => {
              return (
                <div
                  className=" mb-5 d-flex flex-column flex-wrap position-relative"
                  onMouseOver={() => setHoveredItem(index)}
                  onMouseOut={() => setHoveredItem()}
                >
                  <Card
                    className=" mx-3"
                    style={{
                      width: "18rem",
                      height: "12rem",
                      cursor: "pointer",
                    }}
                  >
                    <Card.Img
                      style={{ height: "100%", width: "100%" }}
                      variant="top"
                      src={
                        process.env.REACT_APP_BASEURL +
                        "/api/images/" +
                        item.images[0]
                      }
                    />
                  </Card>
                  <div
                    className="justify-content-center align-items-center position-absolute ml-3 cursor-pointer"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      backgroundColor: "red",
                      opacity: "0.5",
                      borderRadius: "7px",
                      display: hoveredItem == index ? "flex" : "none",
                    }}
                    onClick={() => {
                      setSelectedItem(item);
                      confirm2(item.projectid);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-trash-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
                    </svg>
                  </div>
                  <div
                    className="justify-content-center align-items-center position-absolute ml-3 cursor-pointer"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      backgroundColor: "#343541",
                      opacity: "0.5",
                      borderRadius: "7px",
                      right: "15px",
                      display: hoveredItem == index ? "flex" : "none",
                    }}
                    onClick={() => {
                      setEditItem(item);
                      // confirm2(item.projectid);
                    }}
                  >
                    <svg
                      class="svg-icon"
                      viewBox="0 0 20 20"
                      width="20"
                      height="20"
                    >
                      <path
                        fill="white"
                        d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                      ></path>
                    </svg>
                  </div>
                  <Card.Title
                    className="text-center mt-3  fw-bolder"
                    style={{
                      color: "#073842",
                      fontFamily: "Jost",
                      fontSize: "1.5rem",
                    }}
                  >
                    {item.name}
                  </Card.Title>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
